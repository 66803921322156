import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SocialMediaFixed from '../components/socialmedia-fixed'
import Portfolio from '../components/portfolio'

const PortfolioPage = () => {
    return (
        <>
            <Navbar />
            <Portfolio />
            <Footer />
            <SocialMediaFixed />
        </>
    )
}

export default PortfolioPage
