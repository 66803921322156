import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SocialMediaFixed from '../components/socialmedia-fixed'
import Contact from '../components/contact'

const ContactPage = () => {
    return (
        <>
            <Navbar />
            <Contact />
            <Footer />
            <SocialMediaFixed />
        </>
    )
}

export default ContactPage
