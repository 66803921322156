import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SocialMediaFixed from '../components/socialmedia-fixed'
import Services from '../components/services'

const ServicesPage = () => {
    return (
        <>
            <Navbar />
            <Services />
            <Footer />
            <SocialMediaFixed />
        </>
    )
}

export default ServicesPage
