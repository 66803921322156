import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SocialMediaFixed from '../components/socialmedia-fixed'
import About from '../components/about'

const AboutPage = () => {
    return (
        <>
            <Navbar />
            <About />
            <Footer />
            <SocialMediaFixed />
        </>
    )
}

export default AboutPage
